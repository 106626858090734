import React from "react";

function ButtonPrimary(props) {
  const { classes, link, target, title, rel } = props;
  return (
    <>
      <a
        className={`${"btn btn--primary"} ${classes}`}
        href={link}
        target={target ? target : `${"_self"}`}
        rel={rel ? rel : `${"bookmark"}`}
      >
        {title}
      </a>
    </>
  );
}

export default ButtonPrimary;
