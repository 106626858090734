import React from "react";
import { motion } from "framer-motion";

function CardColumn(props) {
  const { cardsColumnContent } = props;
  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <div className="card-column">
      {cardsColumnContent && (
        <div className="card-column__inner">
          {cardsColumnContent.map((item, index) => (
            <div
              key={index}
              className={`${"card-column__content"} ${
                index % 2 === 0 ? "card-column__even" : "card-column__odd"
              }`}
            >
              <div className="card-column__information">
                <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  variants={textVariants}
                  viewport={{ once: true }}
                >
                  <p className="h3">{`${"0" + (index + 1) + "."}`}</p>
                  <h2 className="h2--alt">{item.title}</h2>
                </motion.div>
                {item.text.length > 1 ? (
                  item.text.map((paragraph, index) => (
                    <motion.div
                      initial="offscreen"
                      whileInView="onscreen"
                      variants={textVariants}
                      viewport={{ once: true }}
                      key={index}
                    >
                      <p className="card-column__text">{paragraph}</p>
                    </motion.div>
                  ))
                ) : (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <p className="card-column__text">{item.text}</p>
                  </motion.div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CardColumn;
