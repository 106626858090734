import React from "react";
import { Container } from "react-bootstrap";
import ButtonPrimary from "../utilities/buttons/ButtonPrimary";
import { Brain, Lightbulb, Peanuts } from "../utilities/Icons";
import { motion } from "framer-motion";

function Contact(props) {
  const {
    title,
    text,
    buttonLink,
    buttonTarget,
    buttonTitle,
    subject,
    sectionClasses,
    image,
    rel,
  } = props;

  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section className={`${"paragraph contact text-center"} ${sectionClasses}`}>
      {sectionClasses === "paragraph--pink" && (
        <div className="contact__section-icon-top">{Peanuts}</div>
      )}
      {sectionClasses !== "paragraph--pink" && (
        <div className="contact__image-top">{Lightbulb}</div>
      )}
      <Container>
        <div className="contact__content">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={textVariants}
            viewport={{ once: true }}
          >
            {sectionClasses === "paragraph--pink" && (
              <div className="contact__icon-top">{Brain}</div>
            )}
            <h2 className="contact__title">{title}</h2>
          </motion.div>
          {image && (
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={textVariants}
              viewport={{ once: true }}
            >
              <img className="contact__image" src={image} alt="" />
            </motion.div>
          )}
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={textVariants}
            viewport={{ once: true }}
          >
            <p className="contact__text">{text}</p>
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={textVariants}
            viewport={{ once: true }}
          >
            <ButtonPrimary
              classes="btn-contact btn--secondary"
              link={`${buttonLink}${subject && subject}`}
              target={buttonTarget ? buttonTarget : `${"_blank"}`}
              title={buttonTitle ? buttonTitle : `${"Get in touch"}`}
              rel={rel}
            />
          </motion.div>
        </div>
      </Container>
      {sectionClasses === "paragraph--pink" && (
        <div className="contact__section-icon-bottom">{Lightbulb}</div>
      )}
    </section>
  );
}

export default Contact;
