import React from "react";
import Contact from "../contact/Contact";
import ContactData from "../../data/contact/Contact.json";
import HeroLanding from "../heroes/HeroLanding";
import HeroLandingData from "../../data/heroes/HeroApproach.json";
import BodySection from "../body/Body";
import ApproachBodyData from "../../data/approachBody/ApproachBody.json";
import TestimonialCarousel from "../utilities/carousel/TestimonialCarousel";
import TestimonialData from "../../data/testimonial/Testimonial.json";
import CardsColumnSection from "../cards-display/CardsColumnSection";
import CardsColumnData from "../../data/cards/CardsColumns.json";

function ApproachPage() {
  const CONTACTDATA = ContactData,
    HEROLANDINGDATA = HeroLandingData,
    APPROACHBODYDATA = ApproachBodyData,
    TESTIMONIALDATA = TestimonialData,
    CARDSCOLUMNDATA = CardsColumnData;
  return (
    <>
      <HeroLanding
        eyebrow={HEROLANDINGDATA[0].eyebrow}
        title={HEROLANDINGDATA[0].title}
        description={HEROLANDINGDATA[0].description}
        ctaText={HEROLANDINGDATA[0].ctaText}
        ctaUrl={HEROLANDINGDATA[0].ctaUrl}
      />
      <BodySection
        articleClasses="article--first"
        image={APPROACHBODYDATA[0].img}
        alt={APPROACHBODYDATA[0].alt}
        bodyCopy={APPROACHBODYDATA[0].approachBody}
      />
      <CardsColumnSection
        title={CARDSCOLUMNDATA[0].title}
        description={CARDSCOLUMNDATA[0].description}
        cardsColumnContent={CARDSCOLUMNDATA[0].cardColumns}
        ctaDesc={CARDSCOLUMNDATA[0].ctaDesc}
        ctaText={CARDSCOLUMNDATA[0].ctaText}
        ctaUrl={CARDSCOLUMNDATA[0].ctaUrl}
        ctaTarget="_blank"
        ctaRel="noopener noreferrer"
      />
      <BodySection
        articleClasses="article--second"
        bodyCopy={APPROACHBODYDATA[1].approachBody}
      />
      <TestimonialCarousel
        testimonialContent={TESTIMONIALDATA[0].approachTestimonial}
        sectionClasses="paragraph--white"
      />
      <Contact
        title={CONTACTDATA[0].title}
        text={CONTACTDATA[0].text}
        sectionClasses="paragraph--pink"
        buttonLink={CONTACTDATA[0].buttonlink}
        subject={CONTACTDATA[0].subject}
        rel="noopener noreferrer"
      />
    </>
  );
}

export default ApproachPage;
