import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import useWindowWidth from "../utilities/WindowWidth";

function Navigation(props) {
  const { navItems, location } = props,
    width = useWindowWidth(),
    [isOpen, setOpen] = useState(false);

  function handleHeaderScroll() {
    setOpen(false);
  }

  function handleResize() {
    setOpen(false);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleHeaderScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleHeaderScroll);
    };
  });

  const navLinks = navItems.map((item, index) => {
    return (
      <li className="navigation__list-item" key={index}>
        <a
          className={`${"navigation__link"}${
            location.pathname !== "/" ? " navigation__link--bg-light" : ""
          }`}
          href={`${item.link}${item.subject ? item.subject : ""}`}
          target={`${item.target ? "_blank" : "_self"}`}
          rel={`${item.target ? "noopener noreferrer" : ""}`}
        >
          {item.name}
        </a>
      </li>
    );
  });

  return (
    <Nav>
      <ul className={`${"navigation__list"}${isOpen ? " isOpen" : ""}`}>
        {navLinks}
      </ul>
      {width <= 768 && (
        <div
          className={`${"hamburger"}${isOpen ? " isOpen" : ""}${
            location.pathname !== "/" ? " hamburger--bg-light" : ""
          }`}
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
    </Nav>
  );
}

export default Navigation;
