import React from "react";
import { ReactComponent as BrainLogo } from "../../assets/images/icons/brain.svg";
import { ReactComponent as LightbulbLogo } from "../../assets/images/icons/lightbulb.svg";
import { ReactComponent as LightbulbFlipLogo } from "../../assets/images/icons/lightbulb-flipped.svg";
import { ReactComponent as PeanutsLogo } from "../../assets/images/icons/peanuts.svg";
import { ReactComponent as StarBoxLogo } from "../../assets/images/icons/star-box.svg";
import { ReactComponent as HeroStarLogo } from "../../assets/images/icons/hero-star.svg";
import { ReactComponent as TwitterLogo } from "../../assets/images/icons/twitter.svg";
import { ReactComponent as InstagramLogo } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as LinkedinLogo } from "../../assets/images/icons/linkedin.svg";
import { ReactComponent as RivalLogo } from "../../assets/images/icons/rival-logo--white.svg";
import { ReactComponent as StarLogo } from "../../assets/images/icons/star.svg";
import { ReactComponent as HandPencilLogo } from "../../assets/images/icons/handpencil.svg";
import { ReactComponent as SquareCircleLogo } from "../../assets/images/icons/squarecircle.svg";
import { ReactComponent as EyeLogo } from "../../assets/images/icons/eye.svg";
import { ReactComponent as AlwaysBetaLogo } from "../../assets/images/icons/always-beta--pink.svg";
// import { ReactComponent as AlwaysBetaPurpleLogo } from "../../assets/images/icons/always-beta--purple.png";
import { ReactComponent as BuildCapabilitiesLogo } from "../../assets/images/icons/build-capabilities.svg";
import { ReactComponent as InventLogo } from "../../assets/images/icons/invent.svg";
import { ReactComponent as StatChartLogo } from "../../assets/images/icons/stat-chart.svg";
import { ReactComponent as StaticStarLogo } from "../../assets/images/icons/static-star.svg";
import { ReactComponent as MuscleFlexLogo } from "../../assets/images/icons/muscle-flex.svg";
import { ReactComponent as ArrowSquiggleLogo } from "../../assets/images/icons/arrow-squiggle.svg";
import { ReactComponent as BigStarLogo } from "../../assets/images/icons/big-star.svg";
import { ReactComponent as PlayLogo } from "../../assets/images/icons/play.svg";
import { ReactComponent as SquiggleLogo } from "../../assets/images/icons/squiggle.svg";
import { ReactComponent as DialogueLogo } from "../../assets/images/icons/dialogue.svg";

export const Brain = <BrainLogo className="brain" />;
export const Lightbulb = <LightbulbLogo className="lightbulb" />;
export const LightbulbFlip = <LightbulbFlipLogo className="lightbulb" />;
export const Peanuts = <PeanutsLogo className="peanuts" />;
export const StarBox = <StarBoxLogo className="starbox" />;
export const HeroStar = <HeroStarLogo className="hero-star" />;
export const Twitter = (
  <TwitterLogo className="social-icon social-icon--twitter" />
);
export const Instagram = (
  <InstagramLogo className="social-icon social-icon--instagram" />
);
export const Linkedin = (
  <LinkedinLogo className="social-icon social-icon--linkedin" />
);
export const RivalWhite = <RivalLogo className="rivallogo" />;
export const Star = <StarLogo className="star" />;
export const HandPencil = <HandPencilLogo className="handpencil" />;
export const SquareCircle = <SquareCircleLogo className="squarecircle" />;
export const Eye = <EyeLogo className="eye" />;
export const AlwaysBeta = <AlwaysBetaLogo className="always-beta" />;
export const AlwaysBetaPurple = require("../../assets/images/icons/always-beta--purple.png");
export const BuildCapabilities = (
  <BuildCapabilitiesLogo className="build-capabilities" />
);
export const Invent = <InventLogo className="invent" />;
export const StatChart = <StatChartLogo className="stat-chart" />;
export const StaticStar = <StaticStarLogo className="static-star" />;
export const MuscleFlex = <MuscleFlexLogo className="muscle-flex" />;
export const ArrowSquiggle = <ArrowSquiggleLogo className="arrow-squiggle" />;
export const BigStar = <BigStarLogo className="big-star" />;
export const IphoneBottom = require("../../assets/images/icons/iphone-bottom.png");
export const IphoneLong = require("../../assets/images/icons/iphone-long.png");
export const Play = <PlayLogo className="play-icon" />;
export const Squiggle = <SquiggleLogo className="squiggle" />;
export const Dialogue = <DialogueLogo className="dialogue" />;
export const StudioPic = require("../../assets/images/icons/studio-pic.png");
