import React from "react";
import { Container } from "react-bootstrap";
import CardColumn from "../utilities/card/CardColumn";
import { Eye, StarBox } from "../utilities/Icons";
import { motion } from "framer-motion";
import ButtonPrimary from "../utilities/buttons/ButtonPrimary";

function CardsColumnSection(props) {
  const {
    title,
    description,
    cardsColumnContent,
    ctaDesc,
    ctaText,
    ctaUrl,
    ctaTarget,
    ctaRel,
  } = props;

  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section className="paragraph paragraph--black cards-column-section">
      <Container>
        <div className="cards-column-section__image-top">{Eye}</div>
        {/* <div className="cards-column-section__image-right">{BigStar}</div> */}
        <div className="cards-column-section__content">
          <div className="cards-column-section__top-section constricted constricted--sides-only">
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={textVariants}
              viewport={{ once: true }}
            >
              {title && (
                <h3
                  className="cards-column-section__title"
                  dangerouslySetInnerHTML={{ __html: `${title}` }}
                />
              )}
              {description && <p>{description}</p>}
            </motion.div>
          </div>
          {cardsColumnContent && (
            <CardColumn cardsColumnContent={cardsColumnContent} />
          )}
          <div className="cards-column-section__bottom-section text-center">
            {ctaDesc && <p>{ctaDesc}</p>}
            <ButtonPrimary
              link={ctaUrl}
              title={ctaText}
              classes="btn--secondary my-4"
              target={ctaTarget}
              rel={ctaRel}
            />
          </div>
        </div>
      </Container>
      <div className="cards-column-section__image-bottom">{StarBox}</div>
    </section>
  );
}

export default CardsColumnSection;
