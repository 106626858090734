import React from "react";
import { Container } from "react-bootstrap";
import {
  HeroStar,
  SquareCircle,
  AlwaysBetaPurple,
  StatChart,
  Lightbulb,
  IphoneBottom,
  IphoneLong,
  Invent,
  Play,
  Squiggle,
  Dialogue,
  StudioPic,
} from "../utilities/Icons";
import { motion } from "framer-motion";
import Throbber from "../utilities/buttons/Throbber";

function Hero() {
  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section className="hero hero--homepage text-center paragraph paragraph--off-white">
      <div className="hero__image-top-left hero__icons">
        <img src={IphoneBottom} alt="" />
      </div>
      <div className="hero__image-top hero__icons">
        <img src={AlwaysBetaPurple} alt="" />
      </div>
      <Container fluid>
        <div className="hero__image-inner-1 hero__icons">{StatChart}</div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          variants={textVariants}
          viewport={{ once: true }}
        >
          <h1 className="hero__title">
            A design lab<span className="hero__title-image">{HeroStar}</span>
            <br /> for th<span className="hero__eye">o</span>se who dare to stray
          </h1>
        </motion.div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          variants={textVariants}
          viewport={{ once: true }}
        >
          <Throbber link="#firstSection" />
        </motion.div>
        <div className="hero__image-outer-1 hero__icons">{Lightbulb}</div>
        <div className="hero__image-outer-2 hero__icons">{Play}</div>
        <div className="hero__image-outer-3 hero__icons">{Dialogue}</div>
        <div className="hero__image-outer-4 hero__icons">{Invent}</div>
        <div className="hero__image-outer-5 hero__icons">{Squiggle}</div>
        <div className="hero__image-outer-6 hero__icons">
          <img src={StudioPic} alt="" />
        </div>
        <div className="hero__image-bottom hero__image-bottom--right">
          {SquareCircle}
        </div>
      </Container>
      <div className="hero__image-outer-7 hero__icons">
        <img src={IphoneLong} alt="" />
      </div>
    </section>
  );
}

export default Hero;
