import React from "react";
import Ticker from "nice-react-ticker";

function TickerTape(props) {
  const { items } = props;

  return (
    <div className="ticker-tape">
      <Ticker>
        {items.map((item, index) => (
          <div className="ticker-tape__item" key={index}>
            <img src={item.icon} alt="" />
          </div>
        ))}
      </Ticker>
    </div>
  );
}

export default TickerTape;
