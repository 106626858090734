import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import logo from "../../logo.svg";
import Navigation from "../navigation/Navigation";
import NavigationData from "../../data/navigation/Navigation.json";

function Header(props) {
  const [scrollPosition, setScrollPosition] = useState("down"),
    NAVDATA = NavigationData,
    { location } = props;
  let initialScrollPos = 0;

  useEffect(
    (initialScrollPos) => {
      const handleScroll = () => {
        if (window.scrollY > initialScrollPos) {
          setScrollPosition("isDown");
        } else if (window.scrollY === 0) {
          setScrollPosition("header--top");
        } else {
          setScrollPosition("up");
        }
        initialScrollPos = window.scrollY;
      };
      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => window.removeEventListener("scroll", handleScroll);
    },
    [initialScrollPos]
  );

  return (
    <header className={`header-wrapper ${scrollPosition}`}>
      <Container fluid>
        <div className="header__content">
          <div className="logo">
            <a href="/">
              <img src={logo} className="App-logo" alt="logo" loading="lazy" />
            </a>
          </div>
          <div className="navigation">
            <Navigation location={location} navItems={NAVDATA[0].nav} />
          </div>
        </div>
      </Container>
    </header>
  );
}

export default Header;
