import React from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";

function BodySection(props) {
  const { articleClasses, eyebrow, title, textTop, bodyCopy, image, alt } = props;

  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <article className={`${articleClasses}`}>
      {(eyebrow || title || textTop) && (
        <Container>
          <div className="article__body">
            {eyebrow && <p className="eyebrow">{eyebrow}</p>}
            {title && <h3 dangerouslySetInnerHTML={{ __html: `${title}` }} />}
            {textTop && (
              <>
                {textTop.length > 1 ? (
                  textTop.map((textItem, index) => (
                    <motion.div
                      initial="offscreen"
                      whileInView="onscreen"
                      variants={textVariants}
                      viewport={{ once: true }}
                      key={index}
                    >
                      <p
                        key={index}
                        dangerouslySetInnerHTML={{ __html: `${textItem}` }}
                      />
                    </motion.div>
                  ))
                ) : (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: `${textTop}` }} />
                  </motion.div>
                )}
              </>
            )}
          </div>
        </Container>
      )}

      {image && (
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          variants={textVariants}
          viewport={{ once: true }}
        >
          <img src={image} alt={alt} loading="lazy" />
        </motion.div>
      )}

      <Container>
        <div id="articleSectionBody"></div>
        <div className="article__body">
          {bodyCopy.length > 1 ? (
            bodyCopy.map((bodyItem, i) => (
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                variants={textVariants}
                viewport={{ once: true }}
                key={i}
              >
                <p
                  key={i}
                  dangerouslySetInnerHTML={{ __html: `${bodyItem}` }}
                />
              </motion.div>
            ))
          ) : (
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={textVariants}
              viewport={{ once: true }}
            >
              <p dangerouslySetInnerHTML={{ __html: `${bodyCopy}` }} />
            </motion.div>
          )}
        </div>
      </Container>
    </article>
  );
}

export default BodySection;
