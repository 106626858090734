import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.scss";
import HomePage from "./components/pages/HomePage";
import ApproachPage from "./components/pages/Approach";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <Header location={location} key={location.pathname} />
      <Routes>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route exact path="/approach" element={<ApproachPage />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
