import React from "react";

function Card(props) {
  const { image, title, text } = props;

  return (
      <div className="card">
        <div className="card__inner">
          <div className="card__header">
            {image && (
              <div className="card__image-wrapper">
                <img src={image} alt="" loading="lazy" />
              </div>
            )}
          </div>
          <div className="card__body">
            {title && <h3 className="card__title title-lower">{title}</h3>}
            {text && <p className="card__text">{text}</p>}
          </div>
        </div>
      </div>
  );
}

export default Card;
