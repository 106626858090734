import React from "react";

function Throbber(props) {
  const { classes, link, target } = props;

  return (
    <>
      <a
        className={`${"throbber"} ${classes}`}
        href={link}
        target={target ? target : `${"_self"}`}
        rel="bookmark"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <p className="visually-hidden">Click to scroll to content.</p>
      </a>
    </>
  );
}

export default Throbber;
