import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Twitter,
  Instagram,
  Linkedin,
  RivalWhite,
  StarBox,
  Star,
} from "../utilities/Icons";
import FooterData from "../../data/footer/Footer.json";

function Footer() {
  const FOOTERDATA = FooterData,
    currentYear = new Date().getFullYear(),
    startYear = 2022;

  return (
    <footer>
      <div className="image-top">{Star}</div>
      <Container>
        <Row>
          <Col xs={12} md={4} xl={3}>
            <div className="logo">
              <a href="/">
                <span className="visually-hidden">Back to home</span>
                {RivalWhite}
              </a>
            </div>
          </Col>
          <Col xs={6} md={4} xl={3}>
            {FOOTERDATA[0].colHeader && (
              <p className="col-header">{FOOTERDATA[0].colHeader}</p>
            )}
            {FOOTERDATA[0].address && (
              <address
                dangerouslySetInnerHTML={{ __html: `${FOOTERDATA[0].address}` }}
              />
            )}
          </Col>
          <Col xs={6} md={4} xl={3}>
            {FOOTERDATA[0].colHeader2 && (
              <p className="col-header">{FOOTERDATA[0].colHeader2}</p>
            )}
            {FOOTERDATA[0].email && (
              <a
                className="text-link"
                href={`${"mailto:"}${FOOTERDATA[0].email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {FOOTERDATA[0].email}
              </a>
            )}
            <div className="social">
              <ul className="social__list">
                {FOOTERDATA[0].socialLinkTW && (
                  <li className="social__list-item">
                    <a
                      className="social__list-link"
                      href={FOOTERDATA[0].socialLinkTW}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Click to go to our Twitter"
                    >
                      {Twitter}
                    </a>
                  </li>
                )}
                {FOOTERDATA[0].socialLinkIG && (
                  <li className="social__list-item">
                    <a
                      className="social__list-link"
                      href={FOOTERDATA[0].socialLinkIG}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Click to go to our Instagram"
                    >
                      {Instagram}
                    </a>
                  </li>
                )}
                {FOOTERDATA[0].socialLinkLI && (
                  <li className="social__list-item">
                    <a
                      className="social__list-link"
                      href={FOOTERDATA[0].socialLinkLI}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Click to go to our Linkedin"
                    >
                      {Linkedin}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </Col>
        </Row>
        <p className="copyright small">
          &copy;{" "}
          {currentYear !== startYear ? (
            <span>
              {startYear} - {currentYear}
            </span>
          ) : (
            startYear
          )}
        </p>
        <div className="image-bottom">{StarBox}</div>
      </Container>
    </footer>
  );
}

export default Footer;
