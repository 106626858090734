import React from "react";
import { Container } from "react-bootstrap";
import { Peanuts, StarBox } from "../utilities/Icons";
import { motion } from "framer-motion";

function Quote(props) {
  const { title, eyebrow } = props;

  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section className="paragraph paragraph--black quote text-center">
      <div className="quote__image-top">{Peanuts}</div>
      <Container>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
        >
          <div className="quote__content">
            {eyebrow && (
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                variants={textVariants}
                viewport={{ once: true }}
              >
                <p className="eyebrow">{eyebrow}</p>
              </motion.div>
            )}

            {title && (
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                variants={textVariants}
                viewport={{ once: true }}
              >
                <h3
                  className="quote__title"
                  dangerouslySetInnerHTML={{ __html: `${title}` }}
                />
              </motion.div>
            )}
          </div>
        </motion.div>
      </Container>
      <div className="quote__image-bottom">{StarBox}</div>
    </section>
  );
}

export default Quote;
