import React from "react";
import Contact from "../contact/Contact";
import ContactData from "../../data/contact/Contact.json";
import Quote from "../quote/Quote";
import QuoteData from "../../data/quote/Quote.json";
import TextMedia from "../text-media/TextMedia";
import TextMediaData from "../../data/text-media/TextMedia.json";
import CardsDisplay from "../cards-display/CardsDisplay";
import CardsData from "../../data/cards/Cards.json";
import Hero from "../heroes/Hero";
import TickerTape from "../utilities/ticker/TickerTape";
import TickerTapeData from "../../data/ticker/Ticker.json";
import { AlwaysBetaPurple, LightbulbFlip } from "../utilities/Icons";
import TestimonialCarousel from "../utilities/carousel/TestimonialCarousel";
import TestimonialData from "../../data/testimonial/Testimonial.json";
import AccordionData from "../../data/accordion/Accordion.json";
import TextAccordion from "../text-accordion/TextAccordion";

function HomePage() {
  const CONTACTDATA = ContactData,
    QUOTEDATA = QuoteData,
    TEXTMEDIADATA = TextMediaData,
    CARDSDATA = CardsData,
    TICKERTAPEDATA = TickerTapeData,
    TESTIMONIALDATA = TestimonialData,
    ACCORDIONDATA = AccordionData;

  return (
    <>
      <Hero />
      <div id="firstSection"></div>
      <Quote eyebrow={QUOTEDATA[0].eyebrow} title={QUOTEDATA[0].title} />
      <TickerTape items={TICKERTAPEDATA} />
      <TextMedia
        eyebrow={TEXTMEDIADATA[0].eyebrow}
        title={TEXTMEDIADATA[0].title}
        text={TEXTMEDIADATA[0].text}
        buttonLink={TEXTMEDIADATA[0].buttonLink}
        buttonTarget={TEXTMEDIADATA[0].buttonTarget}
        buttonTitle={TEXTMEDIADATA[0].buttonTitle}
        imgSrc={TEXTMEDIADATA[0].imgSrc}
        stickerLeft={LightbulbFlip}
        sticker={AlwaysBetaPurple}
      />
      <CardsDisplay
        title={CARDSDATA[0].title}
        cardContent={CARDSDATA[0].cards}
      />
      <TextAccordion
        eyebrow={ACCORDIONDATA[0].eyebrow}
        title={ACCORDIONDATA[0].title}
        text={ACCORDIONDATA[0].text}
        buttonLink={ACCORDIONDATA[0].buttonLink}
        buttonTarget={ACCORDIONDATA[0].buttonTarget}
        buttonTitle={ACCORDIONDATA[0].buttonTitle}
        buttonClasses={ACCORDIONDATA[0].buttonClasses}
        buttonRel={ACCORDIONDATA[0].rel}
        accordionData={ACCORDIONDATA[0].accordionContent}
      />
      <TestimonialCarousel
        testimonialContent={TESTIMONIALDATA[0].testimonials}
        sectionClasses="paragraph--off-white"
      />
      <Contact
        title={CONTACTDATA[0].title}
        text={CONTACTDATA[0].text}
        sectionClasses="paragraph--off-white"
        image={CONTACTDATA[0].image}
        buttonLink={CONTACTDATA[0].buttonlink}
        subject={CONTACTDATA[0].subject}
        rel="noopener noreferrer"
      />
    </>
  );
}

export default HomePage;
