import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick/lib/slider";
import Card from "../card/Card";
import { motion } from "framer-motion";
import useWindowWidth from "../WindowWidth";

function Carousel(props) {
  const { cardContent } = props,
    width = useWindowWidth();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const container = {
    offscreen: { opacity: 1, scale: 0 },
    onscreen: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.2,
        staggerChildren: 0.2,
        when: "beforeChildren",
      },
    },
  };

  const listItem = {
    offscreen: { y: 50, opacity: 0 },
    onscreen: {
      delay: 0.2,
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      {cardContent && (
        <>
          {width <= 1200 ? (
            <Slider {...settings}>
              {cardContent.map((item, index) => (
                <Card
                  image={item.image}
                  title={item.title}
                  text={item.text}
                  key={index}
                />
              ))}
            </Slider>
          ) : (
            <motion.ul
              variants={container}
              initial="offscreen"
              whileInView="onscreen"
              className="card__motion-list"
              viewport={{ once: true }}
            >
              {cardContent.map((item, index) => (
                <motion.li
                  variants={listItem}
                  viewport={{ once: true }}
                  key={index}
                  className="card__motion-list-item"
                >
                  <Card
                    image={item.image}
                    title={item.title}
                    text={item.text}
                  />
                </motion.li>
              ))}
            </motion.ul>
          )}
        </>
      )}
    </>
  );
}

export default Carousel;
