import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ButtonPrimary from "../utilities/buttons/ButtonPrimary";
import { motion } from "framer-motion";
import AccordionMain from "../utilities/accordion/Accordion";
import useWindowWidth from "../utilities/WindowWidth";

function TextAccordion(props) {
  const {
      eyebrow,
      title,
      text,
      buttonClasses,
      buttonLink,
      buttonTarget,
      buttonTitle,
      buttonRel,
      contentClasses,
      accordionData,
    } = props,
    width = useWindowWidth();

  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section
      className={`${"text-accordion paragraph paragraph--black"} ${
        contentClasses ? contentClasses : ""
      }`}
    >
      <Container fluid>
        <Row>
          <Col xs={12} lg={{ span: 7 }} xl={{ span: 6 }}>
            <div className="text-accordion__content">
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
              >
                {eyebrow && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <p className="eyebrow">{eyebrow}</p>
                  </motion.div>
                )}
                {title && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <h2
                      className="text-accordion__title"
                      dangerouslySetInnerHTML={{ __html: `${title}` }}
                    />
                  </motion.div>
                )}
                {text && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <p className="text-accordion__copy">{text}</p>
                  </motion.div>
                )}
                {buttonTitle && width >= 992 && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <ButtonPrimary
                      classes={buttonClasses}
                      link={buttonLink}
                      target={buttonTarget}
                      title={buttonTitle}
                      rel={buttonRel}
                    />
                  </motion.div>
                )}
              </motion.div>
            </div>
          </Col>
          <Col
            className="col"
            xs={{ span: 12 }}
            lg={{ span: 5 }}
            xl={{ span: 5, offset: 1 }}
          >
            {/* <div> */}
              <AccordionMain accordionData={accordionData} />
              {buttonTitle && width <= 992 && (
                <div className="mt-5">
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <ButtonPrimary
                      classes={buttonClasses}
                      link={buttonLink}
                      target={buttonTarget}
                      title={buttonTitle}
                      rel={buttonRel}
                    />
                  </motion.div>
                </div>
              )}
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default TextAccordion;
