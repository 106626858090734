import React from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import ButtonPrimary from "../utilities/buttons/ButtonPrimary";
import { AlwaysBeta, ArrowSquiggle, MuscleFlex } from "../utilities/Icons";

function HeroLanding(props) {
  const { eyebrow, title, description, ctaText, ctaUrl } = props;

  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };
  return (
    <section className="hero hero--landing paragraph">
      <Container fluid>
        <div className="hero__content-wrapper">
          <div className="hero__sticker-top">
            {MuscleFlex}
          </div>
          {eyebrow && (
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={textVariants}
              viewport={{ once: true }}
            >
              <p className="eyebrow">{eyebrow}</p>
            </motion.div>
          )}
          {title && (
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={textVariants}
              viewport={{ once: true }}
            >
              <h1
                className="hero__title"
                dangerouslySetInnerHTML={{ __html: `${title}` }}
              />
            </motion.div>
          )}
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={textVariants}
            viewport={{ once: true }}
          >
            {description && (
              <p
                className="hero__description"
                dangerouslySetInnerHTML={{ __html: `${description}` }}
              />
            )}
            {ctaText && <ButtonPrimary title={ctaText} link={ctaUrl} />}
          </motion.div>
          <div className="hero__sticker-bottom">
            {ArrowSquiggle}
          </div>
        </div>
      </Container>
      <div className="hero__sticker-outer">
        {AlwaysBeta}
      </div>
    </section>
  );
}

export default HeroLanding;
