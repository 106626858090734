import React from "react";
import { Accordion } from "react-bootstrap";

function AccordionMain(props) {
  const { accordionData } = props;

  return (
    <Accordion>
      {accordionData.map((item, index) => (
        <Accordion.Item
          eventKey={`${0 + index}`}
          key={index}
          className={!item.accordionBody && `${"accordion-item--no-body"}`}
        >
          <Accordion.Header>
            {item.mainTitle && <span>{item.mainTitle}</span>}
            {item.accordionTitle && item.accordionTitle}
          </Accordion.Header>
          {item.accordionBody && (
            <Accordion.Body>{item.accordionBody}</Accordion.Body>
          )}
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default AccordionMain;
