import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick/lib/slider";
import { Container } from "react-bootstrap";
import Placeholder from "../../../assets/images/icons/testimonial-placeholder.svg";
import { motion } from "framer-motion";

function TestimonialCarousel(props) {
  const { testimonialContent, sectionClasses } = props;
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section className={`${"paragraph testimonial"} ${sectionClasses}`}>
      <Container>
        {sectionClasses === "paragraph--white" && (
          <div className="divide-pip"></div>
        )}
        {testimonialContent && (
          <Slider {...settings}>
            {testimonialContent.map((item, index) => (
              <div className="testimonial__content text-center" key={index}>
                {item.testimonial && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <h4 className="title-initial">{item.testimonial}</h4>
                  </motion.div>
                )}
                {item.image ? (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <div className="testimonial__image">
                      <img
                        src={item.image}
                        alt={`${item.name + item.job_title + " photo"} `}
                        loading="lazy"
                      />
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <div className="testimonial__image">
                      <img
                        src={Placeholder}
                        alt="Testimonial placeholder"
                        loading="lazy"
                      />
                    </div>
                  </motion.div>
                )}
                {item.name && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <p className="testimonial__name">
                      {item.name}
                      {item.job_title && <span>, {item.job_title}</span>}
                    </p>
                  </motion.div>
                )}
                {item.company && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <p className="testimonial__company text text--orange">
                      {item.company}
                    </p>
                  </motion.div>
                )}
              </div>
            ))}
          </Slider>
        )}
      </Container>
    </section>
  );
}

export default TestimonialCarousel;
