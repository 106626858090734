import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ButtonPrimary from "../utilities/buttons/ButtonPrimary";
import { motion } from "framer-motion";

function TextMedia(props) {
  const {
    eyebrow,
    title,
    text,
    buttonClasses,
    buttonLink,
    buttonTarget,
    buttonTitle,
    imgSrc,
    contentClasses,
    sticker,
    stickerLeft,
  } = props;

  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section
      className={`${"text-media"} ${contentClasses ? contentClasses : ""}`}
    >
      <Container fluid>
        <Row>
          <Col xs={12} xl={{ span: 6, order: 2 }} xxl={7}>
            <div className="paragraph text-media__content">
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
              >
                {eyebrow && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <p className="eyebrow">{eyebrow}</p>
                  </motion.div>
                )}
                {title && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <h2
                      className="text-media__title"
                      dangerouslySetInnerHTML={{ __html: `${title}` }}
                    />
                  </motion.div>
                )}
                {text && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <p className="text-media__copy">{text}</p>
                  </motion.div>
                )}
                {buttonLink && (
                  <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={textVariants}
                    viewport={{ once: true }}
                  >
                    <ButtonPrimary
                      classes={buttonClasses}
                      link={buttonLink}
                      target={buttonTarget}
                      title={buttonTitle}
                    />
                  </motion.div>
                )}
              </motion.div>
            </div>
          </Col>
          <Col
            className="col"
            xs={{ span: 12, order: "first" }}
            xl={{ span: 6, order: 2 }}
            xxl={5}
          >
            {imgSrc && (
              <div className="text-media__image-wrapper">
                <div
                  className="text-media__image"
                  style={{ backgroundImage: `url(${imgSrc})` }}
                ></div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {(stickerLeft || sticker) && (
        <div className="text-media__sticker">
          {stickerLeft && (
            <div className="text-media__sticker-left">{stickerLeft}</div>
          )}
          {sticker && (
            <div className="text-media__sticker-right">
              {/* Maybe put a check in here as the current image is a png, this will break if an object is used */}
              <img src={sticker} alt="" width="228" height="267" />
            </div>
          )}
        </div>
      )}
    </section>
  );
}

export default TextMedia;
