import React from "react";
import { Container } from "react-bootstrap";
// import ButtonPrimary from "../utilities/buttons/ButtonPrimary";
import Carousel from "../utilities/carousel/Carousel";
import { motion } from "framer-motion";

function CardsDisplay(props) {
  const { title, cardContent } = props;

  const textVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section className="paragraph paragraph--black cards-display text-center">
      <Container fluid>
        <div className="cards-display__content">
          {title && (
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={textVariants}
              viewport={{ once: true }}
            >
              <h3 className="cards-display__title">{title}</h3>
            </motion.div>
          )}
          {cardContent && <Carousel cardContent={cardContent} />}
          {/* <ButtonPrimary
            classes="btn--secondary btn--secondary-alt"
            title="More about our approach"
            link="/approach"
          /> */}
        </div>
      </Container>
    </section>
  );
}

export default CardsDisplay;
